import $ from "jquery";
import 'jquery-confirm';
import './redirect';

jconfirm.defaults = {
    content: 'Czy na pewno chcesz kontynuować?',
    draggable: false,
    scrollToPreviousElement: false,
    scrollToPreviousElementAnimate: false,
    backgroundDismiss: true,
    animateFromElement: false,
    icon: 'exclamation-mark',
    defaultButtons: {
        ok: {
            text: 'OK',
        },
        close: {
            text: 'Anuluj',
        },
    }
};

$(document.body).on('click', '.show-spoiler' ,function(){
    let name = $(this).attr('data-name');
    let $showSpoiler = $('.spoiler[data-name="' + name + '"]');
    if($showSpoiler) {
        if($showSpoiler.css('display') === 'none') {
            $showSpoiler.show();
        } else {
            $showSpoiler.hide();
        }
    }
});

$(document).ready(function() {
    $('#jbms-scroll-top').on('click', function() {
        $('html,body').animate({scrollTop: 0}, 'fast');
    });

    var image = 1;
    $('.news a[href$=jpg], .news a[href$=png], .news a[href$=gif]').each(function() {
        $(this).attr('data-lightbox', 'image-' + image++);
    });

    $('.comment-operation-delete').confirm({
        title: 'Usuwanie komentarza',
        content: 'Czy na pewno chcesz usunąć ten komentarz?',
        buttons: {
            confirm: {
                text: 'Tak',
                btnClass: 'red',
                action: function() {
                    $.redirect(this.$target.attr('href'), {_csrfToken: $('meta[name="csrfToken"]').attr('content')}, 'post');
                }
            },
            cancel: {
                text: 'Anuluj',
                function () {
                }
            }
        }
    });

    $('.comment-operation-edit').click(function(e) {
        e.preventDefault();

        let $comment = $(this).closest('.comment');
        let id = $comment.data('commentId');
        let href = $(this).attr('href');
        let content = $comment.find('.comment-content-text').text();
        let originalContent = $comment.find('.comment-content').text();

        $comment.find('.comment-content').html(
            $('<form />', {method: 'post', action: href}).append(
                $('<input />', {type: 'hidden', name: '_csrfToken', value: $('meta[name="csrfToken"]').attr('content')}),
                $('<textarea />', {name: 'content', placeholder: 'Treść komentarza'}).val(content),
                $('<button />', {type: 'submit', text: 'Edytuj komentarz'}),
            )
        );
    });

});

var JB = JB || {};

JB.menu = {
    init: function() {
        var self = this;

        if($('.jbms-menu-chat').length) {
            self.chat();
            window.setInterval(self.chat, 30000);
        }

        $('.jbms-menu-submenu').each(function() {
            $('.jbms-menu-submenu').click(function(e) {
                e.stopPropagation();
            });
            $(this).parent().click(self.subMenu);
        });

        $(document).click(function() {
            if($(window).outerWidth() > 999) {
                $('.jbms-menu-submenu').hide();
            }
        });

        $('#jbms-scroll-top').on('click', function() {
            $('html,body').animate({scrollTop: 0}, 'fast');
        });
    },
    subMenu: function(e) {
        if($(window).outerWidth() > 999) {
            return;
        }

        if($(this).hasClass('jbms-menu-chat')) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        let  $submenu = $(this).find('.jbms-menu-submenu');

        $('.jbms-menu-submenu').not($submenu).hide();


        if ($submenu.css('display') === 'none') {
            $submenu.css('display', 'block');
        } else {
            $submenu.css('display', 'none');
        }
    },
    chat: function() {
        let $chat = $('.jbms-menu-chat');
        $.getJSON('/session', function (data) {
            $chat.find('.inner').show();
            let users = [];
            $.each(data.chat, function(userId, value) {
                users.push(value.name);
            });
            $chat.find('.jbms-menu-submenu').remove();
            if(Object.keys(data.chat).length) {
                var $list = $('<ul />')
                $.each(users, function(key, userName) {
                    $('<li />').text(userName).appendTo($list);
                });
                $('<div />', {class: 'jbms-menu-submenu'}).append($list).appendTo($chat);
                $chat.find('span.inner').text('płonie');
                $chat.addClass('jbms-menu-chat-on').removeClass('jbms-menu-chat-off');
            } else {
                $chat.find('span.inner').text('');
                $chat.addClass('jbms-menu-chat-off').removeClass('jbms-menu-chat-on');
            }

        });
    }

};
$(document).ready(function() {
    JB.menu.init();
});
